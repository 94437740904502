html {
	height: 100%;
	margin: 0;
	padding: 0;
}

body {
	height: 100%;
	margin: 0;
	padding: 0;
	font-family: Roboto, "Helvetica Neue", sans-serif;
	width:100%;
}
// Slick and sexy scrollbar
::-webkit-scrollbar {
  width: 6px;   // for vertical scroll bar
  height: 6px;  // for horizontal scroll bar
}
::-webkit-scrollbar-track {
  background: #ddd;
}
::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}

.page {
  height:100%;
  padding:0;
  margin:0;
  width:100%;
}

.mat-cell {
  min-width: 100px;
}

.tab-icon {
  margin-right: 8px;
}

// Error icon
.fa-icon-red {
  color: var(--warn-lighter-color);
}

// External Href
a {
  color: var(--primary-color);
}

// Hide element
.hide { display: none; }

// Icon progress animation
.blink {
  animation:blinking 1.2s infinite;
}

// Grey out
.disabled {
  filter: grayscale(100%) opacity(54%);
}

.error-container {
  border: thin solid var(--warn-lighter-color);
}

@keyframes blinking{
  0% { filter:opacity(100%); }
  49% { filter:opacity(100%); }
  60% { filter:opacity(50%); }
  99% { filter:opacity(50%); }
  100% { filter:opacity(100%); }
}

// Hide arrows in number input
// https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

// When those nasty horizontal scrolls show up
// Break the nosey long strings with this class
.wrap {
  word-break: break-all;
}

// Make card header take full width
.mat-card-header-text {
  flex: 1 1 auto
}

// Profile image common to components
.header-img-card {
  margin: 0 auto;
  padding-top: 32px;

  .header {
      height: 200px;

      .avatar {
          position: relative;
          margin: 0 auto;
          width: 160px;
          height: 160px;

          img {
              width: 160px;
              height: 160px;
              display: block;
              border-radius: 50%;
              position: absolute;
              bottom: calc(-1*(20px));
              border: 8px solid var(--primary-color);
              background-color: var(--primary-color);
          }
      }
  }
}

// Classes to show hide toolbar and tabs
.app-toolbar {
  width: 100%;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  transform: translate3d(0, 0, 0);
  transition: transform ease 200ms;
  &.poof {
    transform: translate3d(0, -100%, 0);
  }
}

.app-tabs {
  width: 100%;
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;
  max-height: 48px;
  transition: max-height ease 200ms;
  &.poof {
    max-height: 0px;
  }
}

.app-fab-button {
  position: absolute;
  right: 16px;
  bottom: 60px;
  transition: transform ease 200ms;
  &.poof {
    transform: bottom ease 200ms;
    bottom: 16px;
  }
}

// outline for z0 elevation cards
.outline {
  border-style: solid;
  border-color: grey;
  border-width: 1px;
}

// class to automatically separate items in flexbox
.spacer {
  flex: 1 1 auto;
}

// Break long strings in snackbar
simple-snack-bar span {
  word-break: break-all;
}

// Hubspot related code
#hubspot-conversations-inline-parent {
  height: 100%;
  z-index: 90;
}

#hubspot-conversations-inline-iframe {
  border-radius: 4px;
  width: 100%;
  height: 100%;
  border: none; margin: 0; padding: 0;
  @media screen and (max-width: 576px) {
    border-radius: 0px;
  }
}

// // https://stackoverflow.com/a/907719
// @media print {
//   div {
//     break-inside: avoid;
//   }
// }

@page {
  /* No margin, hide the header and footer. */
  margin: 0;
  size: A4;
}
